<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="data" :row-key="_id" :loading="tableLoading">
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div class="custom-filter-dropdown">
          <a-input
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm)"
            >Search</a-button
          >
          <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
            >Reset</a-button
          >
        </div>
      </template>
      <template #filterIcon="{ text: filtered }">
        <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #createdAt={text}>
        {{moment(text).format('DD-MM-YYYY')}}
      </template>
    </a-table>
  </div>
</template>

<script>
/* eslint-disable */
import ApiClient from "@/services/axios"
import { message } from 'ant-design-vue';
export default {
  created() {
      this.tableLoading=true
    ApiClient.post('/accessInformation/filter',{
      query:{}
    }).then((res)=>{
      this.data = res.data
    }).finally(()=>{
      this.tableLoading=false
    })
  },
  data(){
    return {
      tableLoading:false,
      columns:[             
        {
          title: 'الاسم و اللقب / التسمية الاجتماعية',
          dataIndex: 'fullName',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'name',
          },
          sorter: (a, b) => a.name - b.name,
          onFilter: (value, record) => record.name.toLowerCase().includes(value.toLowerCase()),
        }, 
        {
          title: 'العنوان /المقر الاجتماعي',
          dataIndex: 'adress',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'lastName',
          },
          sorter: (a, b) => a.lastName - b.lastName,
          onFilter: (value, record) => record.lastName.toLowerCase().includes(value.toLowerCase()),
        },     
        {
          title: 'E-mail',
          dataIndex: 'email',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'email',
          },
          sorter: (a, b) => a.email - b.email,
          onFilter: (value, record) => record.email.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'الهاتف',
          dataIndex: 'phone',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'phone',
          },
          sorter: (a, b) => a.phone - b.phone,
          onFilter: (value, record) => record.phone.toLowerCase().includes(value.toLowerCase()),
        },  
        {
          title: 'الفاكس',
          dataIndex: 'fax',
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'phone',
          },
          sorter: (a, b) => a.phone - b.phone,
          onFilter: (value, record) => record.phone.toLowerCase().includes(value.toLowerCase()),
        }, 
        {
          title: 'الهيكل العمومي المعنى',
          dataIndex: 'pp',
          width:"50%",
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'government',
          },
          sorter: (a, b) => a.message - b.message,
          onFilter: (value, record) => record.message.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'المعلومات المطلوبة',
          dataIndex: 'requestedInformation',
          width:"50%",
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'government',
          },
          sorter: (a, b) => a.message - b.message,
          onFilter: (value, record) => record.message.toLowerCase().includes(value.toLowerCase()),
        },
        {
          title: 'شكل التحصل على المعلومة',
          dataIndex: 'type',
          width:"50%",
          slots: {
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
              customRender: 'government',
          },
          sorter: (a, b) => a.message - b.message,
          onFilter: (value, record) => record.message.toLowerCase().includes(value.toLowerCase()),
        },                                                            
      ],
      searchText:'',
      searchInput:null,
      data:[],
    }
  },
  methods: {
    handleReset(){
      clearFilters(clearFilters)
      searchText.value = ''
    },
    handleSearch (selectedKeys, confirm){
      confirm()
      searchText.value = selectedKeys[0]
    }
  },
}
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
