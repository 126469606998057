<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <vb-headers-tag :data="{ title: 'Accées a l information' }" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-top card-top-danger">
          <div class="card-header py-0">
            <vb-headers-card-header-tabbed :data="{ title: 'Liste des demandes' }" />
          </div>
            <div class="card-body"><accessInformationTable /></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersTag from '@/components/widgets/Headers/Tag'
import VbHeadersCardHeaderTabbed from '@/components/widgets/Headers/CardHeader4'
import accessInformationTable from '@/components/widgets/TablesAntd/AcceesInformation'

export default {
  name: 'VbPublications',
  components: {
    VbHeadersTag,
    VbHeadersCardHeaderTabbed,
    accessInformationTable,
  },
}
</script>
